<template>
    <v-container fill-height>
        <v-layout justify-center wrap>
            <v-flex md12>
                <material-card :title="title + ' ' + factory_count">
                    <v-card>
                        <v-card-title>
                            Filter:
                        </v-card-title>

                        <v-card-text>
                             <v-container>
                                 <v-row>
                                     <v-col cols="12" sm="4" md="4" lg="4">
                                         <v-text-field
                                                 v-model="filter.name"
                                                 label="Name"
                                         ></v-text-field>
                                     </v-col>
                                     <v-col cols="12" sm="2" md="2" lg="2">
                                         <v-btn
                                                 color="blue-grey darken-4"
                                                 fab
                                                 text
                                                 @click="filter_results()"
                                         >
                                             <v-icon>
                                                 mdi-magnify
                                             </v-icon>
                                         </v-btn>
                                     </v-col>
                                 </v-row>
                             </v-container>
                        </v-card-text>
                    </v-card>

                    <v-data-table :headers="headers"
                                  :items="factories"
                                  :loading="loading"
                                  :loading-text="'Loading please wait'"
                                  header-props.sort-icon="mdi-menu-up"
                                  item-key="id"
                                  hide-default-footer
                                  light>
                        <template v-slot:item.actions="{ item }">
                            <v-btn small rounded text fab
                                   :to="'/c-panel/sourcer/factory/'+item.id"
                                   v-tooltip="'Edit Product'">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn
                                  color="blue-grey darken-4"
                                  :to="'/c-panel/sourcer/products/'+item.id"
                                  fab
                                  text
                                >
                                  <v-icon>
                                      mdi-eye
                                  </v-icon>
                                </v-btn>

                            <v-btn
                                    fab
                                    text
                                    :loading="loading"
                                  color="blue-grey darken-4"
                                  @click="download_factory_products($event, item)"
                                >
                                  <v-icon>
                                      mdi-cloud-download
                                  </v-icon>
                                </v-btn>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <div v-bind:class="{'red': !item.user || !item.user.profile || item.user.profile.phone === '' || item.user.email == '' }">
                                <span style="line-height:56px;" @click="show_factory_details(item)"

                                >
                                {{ item.name }}
                            </span>
                            </div>

                        </template>
                    </v-data-table>

                    <div class="text-center mt-2">
                        <v-pagination v-model="page"
                                      circle
                                      :total-visible="7"
                                      :length="max_count"
                        ></v-pagination>
                    </div>
                </material-card>
            </v-flex>
        </v-layout>
        <v-dialog v-model="factory_dialog" width="800">
            <v-card>
                <v-card-title>
                    {{ factory.name }} Details
                </v-card-title>
                <v-card-text>
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="indigo">mdi-phone</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ factory.user.profile.phone }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Phone Num</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="indigo">mdi-email</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ factory.user.email }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Email</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="indigo">mdi-map-marker-radius</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ factory.user.profile.address }},
                                    {{ factory.user.profile.region }},
                                    {{ factory.user.profile.zip_code }},
                                    {{ factory.user.profile.country_data.name }},

                                </v-list-item-title>
                                <v-list-item-subtitle>Address</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="indigo">mdi-web</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ factory.website }}

                                </v-list-item-title>
                                <v-list-item-subtitle>Website</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer>

                    </v-spacer>
                    <v-btn
                          color="blue-grey darken-4"
                          @click="factory_dialog = false"
                        >
                          Close
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>

</template>

<script>
    import {saveAs} from 'file-saver'
    import {
        gen_sourcer_download
    } from '@/plugins/excel_pi_po_generator';

    export default {
        name: "factories",
        data: () => ({
            title: "Factories",
            factories: [],
            headers: [
                {
                    text: '#',
                    align: 'center',
                    value: 'id'
                },
                {
                    text: 'Name',
                    align: 'left',
                    value: 'name'
                },
                {
                    text: 'Actions',
                    align: 'left',
                    value: 'actions'
                },
            ],
            factory_dialog:false,
            factory: {
                name: '',
                user:{
                    profile: {country_data:{name:''}}
                }
            },
            loading:true,
            filter:{},
            filter_url:'',
            factory_count: 0,
            page:1,
            offset:0,
            limit:50,
            max_count:0,
            user:{},
        }),
        created () {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.load_factories();
        },
        watch: {
            page(val) {
                this.load_factories();
            },
        },
        methods:{
            async download_factory_products(event, factory) {
                this.loading = true;
                let data = await this.$hget('/sourcers/download/'+factory.id+'/').then(r => r.data);
                console.log(data);

                let worksheet_buffer = await gen_sourcer_download(this, data, this.user);
                await saveAs(new Blob([worksheet_buffer]), 'factory-'+factory.name+ ".xlsx");
                this.loading = false;

            },
            filter_results()
            {
                let filter_url = '';
                let n =0;
                this.filter_url = '';
                for(let i in this.filter){
                    if(this.filter[i] !== '')
                    {
                        filter_url += '&'+ i+'='+this.filter[i];
                        n++;
                    }
                }
                this.filter_url = filter_url;
                this.load_factories();
            },
            show_factory_details(factory)
            {
                this.factory = factory;
                this.factory_dialog = true;
            },
            async load_factories()
            {
                let offset = (this.page - 1) * this.limit;

                this.$http.get('/sourcers/organisations/?limit=50&offset=' + offset +this.filter_url)
                    .then((response)=>{
                        console.log(response.data);
                        this.factories = response.data.results;
                        this.loading = false;
                        this.factory_count = response.data.count;
                        this.max_count = Math.ceil((this.factory_count + 25) / this.limit);
                    })
                    .catch((error)=>{
                        this.loading = false;
                        console.log(error);
                    });
            },
            seekValue(item, value){
                if(value.includes('.')) {
                    let map = value.split('.');
                    let res = item;
                    for (let pos of map)
                        res = res[pos] ? res[pos] : res;
                    return res;
                } else return item[value];
            }
        }
    }
</script>

<style scoped>

</style>
